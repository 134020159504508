@import "src/assets/scss/variables/colors";
@import "src/assets/scss/variables/layout";
@import "src/assets/scss/variables/misc";
@import "src/assets/scss/mixins/mixins";

.Message {
  margin: 0 0 20px 0;

  .Message__content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 10px;
  }

  &__image {
    @include roundedImage($color__white);
  }

  &__messages{
    padding: 0;
    position: relative;
    flex: 1;

    > p {
      color: $color__text-main;
      font-size: 1.4rem;
    }

    > p:not(:last-of-type) {
      margin: 0 0 10px 0;
    }
  }

  .Message__time {
    font-size: 10px;
  }

  &--human {
    .Message__image {
      margin: 0 20px 0 0;
    }
  }

  &--robot {
    margin-left: auto;

    .Message__image {
      order: 1;
      margin: 0 0 0 20px;
    }

    .Message__messages{
      order: 0;
    }
  }
}
