@import "../../../assets/scss/variables/misc";

@mixin roundedImage($border__color) {
  width: $chat__images-width;
  height: $chat__images-height;
  border-radius: 50%;
  border: $chat__images-border-width solid $border__color;
  position: relative;
  overflow: hidden;

  img {
    height: 100%;
    width: 100%;
    display: block;
    object-fit: cover;
  }
}
