$brakepoint__mac-min: 1440px;
$brakepoint__mac-max: 1439px;
$brakepoint__vlg-min: 1400px;
$brakepoint__vlg-max: 1399px;
$brakepoint__mdpi-min: 1280px;
$brakepoint__mdpi-max: 1279px;
$brakepoint__lg-min: 1200px;
$brakepoint__lg-max: 1199px;
$brakepoint__ipad-min: 1025px;
$brakepoint__ipad-max: 1024px;
$brakepoint__md-min: 992px;
$brakepoint__md-max: 991px;
$brakepoint__sm-min: 768px;
$brakepoint__sm-max: 767px;
$brakepoint__sx-min: 576px;
$brakepoint__sx-max: 575px;
$brakepoint__sxx-min: 401px;
$brakepoint__sxx-max: 400px;
