h1,
h2,
h3,
h4,
h5,
h6 {
  clear: both;
  color: $color__text-main;
  margin: 1rem 0;
  line-height: 1.2;
}

h1 {
  font-size: 3rem;
  font-weight: 700;
}

h2 {
  font-size: 2.6rem;
  font-weight: 700;
}

h3 {
  font-size: 2rem;
  font-weight: 500;
}

h4 {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 2.5rem;
}

h5 {
  font-size: 1.6rem;
  font-weight: 400;
}

h6 {
  font-size: 1.4rem;
  font-weight: 400;
}
