@import "src/assets/scss/variables/misc";

.Products {
  display: block;
  margin: 0;
  overflow: hidden;

  .Products__message {
    display: block;
    width: 100%;
    padding: 5px $chat__padding-lr;
    text-align: center;
  }
}
