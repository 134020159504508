@import "../../assets/scss/variables/colors";
@import "../../assets/scss/variables/layout";
@import "../../assets/scss/variables/misc";

.Chat {
  z-index: 100;
  background-color: $background__chat;
  position: relative;
  max-width: $brakepoint__sm-max;
  margin: 0 auto;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;

  > header {
    margin: 0;
    border-bottom: 2px solid $color__white;
    padding: 0 $chat__padding-lr;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    h1 {
      margin: 0;
      padding: 0;
      line-height: 1;
      font-size: 2rem;
    }
  }

  > main  {
    padding: 15px $chat__padding-lr;
    flex: 1;
    width: 100%;
    overflow-y: auto;
  }

  > footer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-top: 2px solid $color__white;
    font-size: 12px;
    position: relative;

    > .Products {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      transform: translateY(-100%);
      width: 100%;
      background-color: $background__chat-secondary;
      transition: height 0.3s ease-in-out, opacity 0.4s ease-in-out;

      &--hide {
        height: 0;
        opacity: 0;
      }

      &--show {
        height: 190px;
        opacity: 1;
      }

      .Products__wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        overflow-y: hidden;
        overflow-x: scroll;
        height: 150px;
        opacity: 1;
        flex-wrap: nowrap;
        margin: 10px 0 0 0;
      }

      .Product {
        margin: 0 10px;
        padding: 10px 5px;
      }

      .Product__price {
        position: relative;
        background-color: $color__text-secondary;
        color: $color__white;
        padding: 5px;
        border-radius: 3px;
        margin: 5px 0 0 0;
        display: inline-block;
        clear: both;
      }
    }

    > small {
      margin: 10px 0 0 0;
      padding: 5px $chat__padding-lr 10px $chat__padding-lr;
    }
  }

  @media all and (display-mode: browser){
    height: 90vh;
  }

  @media all and (min-width: $brakepoint__sx-min){
    .Product {
      flex: 0 0 28vw;
    }
  }

  @media all and (max-width: $brakepoint__sx-max) and (min-width: $brakepoint__sxx-min){
    .Product {
      flex: 0 0 38vw;
    }
  }

  @media all and (max-width: $brakepoint__sxx-max){
    .Product {
      flex: 0 0 55vw;
    }
  }
}
